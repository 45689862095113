<template>
    <div>
        <!-- contact us -->
        <section class="faq-contact">
            <b-row class="mt-5 pt-75 text-center">
                <b-col cols="12">
                    <h2>You still have a question?</h2>
                    <b-card-text class="mb-3">
                        If you cannot find a question in our FAQ, you can always contact us. We will answer to you
                        shortly!
                    </b-card-text>
                </b-col>
                <b-col sm="4">
                    <b-card class="shadow-none py-1 faq-contact-card">
                        <b-avatar size="42" rounded variant="light-primary" class="mb-2">
                            <feather-icon icon="PhoneCallIcon" size="18" />
                        </b-avatar>
                        <h4>+44 (744) 144-13-34</h4>
                        <span class="text-body">We are always happy to help!</span>
                    </b-card>
                </b-col>
                <b-col sm="4">
                    <b-card class="shadow-none py-1 faq-contact-card">
                        <b-link href="mailto:contact@oneposts.io" target="_blank">
                            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
                                <feather-icon icon="MailIcon" size="18" />
                            </b-avatar>
                            <h4>contact@oneposts.io</h4>
                            <span class="text-body">Best way to get answer faster!</span>
                        </b-link>
                    </b-card>
                </b-col>
                <b-col sm="4">
                    <b-card class="shadow-none py-1 faq-contact-card">
                        <b-link href="https://wa.me/447441441334" target="_blank">
                            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
                                <feather-icon icon="MessageCircleIcon" size="18" />
                            </b-avatar>
                            <h4>+44 (744) 144-13-34</h4>
                            <span class="text-body">We are always available on whatsapp!</span>
                        </b-link>
                    </b-card>
                </b-col>
            </b-row>
        </section>
        <!--/ contact us -->
    </div>
</template>



<script>
import {
    BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar, BLink
} from 'bootstrap-vue'

export default {
    components: { 
        BForm, BCard, BRow, BCol, BAvatar, BCardBody, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
        BTabs, BTab, BImg, BLink
    },
    mounted(){
        var d = document.createElement('script');
        d.innerHTML = `
            (function(d,t) {
                var BASE_URL="https://social.social-bot.io";
                var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                g.src=BASE_URL+"/packs/js/sdk.js";
                g.defer = true;
                g.async = true;
                s.parentNode.insertBefore(g,s);
                g.onload=function(){
                window.socialBotSDK.run({
                    websiteToken: 'MZ2oqBcTpBdp9iry2zVqnAnU',
                    baseUrl: BASE_URL
                })
                }
            })(document,"script");
        `
        document.body.appendChild(d)
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style>
.woot-widget-bubble {
    border-radius: 100px !important;
}
</style>